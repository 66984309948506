:root {
  --tui-background-accent-1: #2a61ed; /* default: #526ed3 */
  --tui-background-accent-1-hover: #5581f1; /* default: #6c86e2; */
  --tui-text-primary: rgba(25, 28, 48, 0.9); /* default: #1b1f3b; */
  --tui-text-action: #2a61ed; /* default: #526ed3; */
  --tui-text-action-hover: #5581f1; /* default: #6c86e2; */
  --ntc-page-bg: #f7f7f8; /* Дефолтный цвет бэкграунда для всех страниц (нижний слой) */

  /*
    Для каждой подсистемы определяем базовые показатели цвета
    через rgb для возможности играть с прозрачностью на месте
    использования переменной.

    Дополнительно позволяет отделить цвета подсистем от библиотечных
  */
  --ntc-ss-layout: 58, 169, 129;
  --ntc-ss-plumbing: 54, 130, 219;
  --ntc-ss-structure: 52, 180, 31;
  --ntc-ss-hvac: 255, 138, 0;
  --ntc-ss-omar: 123, 67, 158;
  --ntc-ss-electrical: 252, 187, 20;
  --ntc-ss-space-planning: 189, 101, 164;
  --ntc-ss-final: 142, 228, 175;
  --ntc-ss-mep: 184, 71, 78;
}
