tui-accordion-item._sticky {
  overflow: visible;

  & > .t-wrapper > .t-header {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: var(--tui-background-base);
  }
}
