@import '@taiga-ui/core/styles/taiga-ui-local';

/*
Для всех слайдеров и ренжей нужно повысить z-index поскольку лейблы
для тиков перекрывают половину кругляшка за который можно тянуть
*/
tui-input-slider,
tui-input-range {
  z-index: 1;
}

.slider-ticks-labels {
  color: var(--tui-text-secondary);
  margin-top: 0.5rem;

  @include tui-slider-ticks-labels();
}
