@import 'styles/common';
@import 'styles/taiga-overwrites';

body {
  min-width: 1200px;
}

.hidden-field {
  display: none;
}
