:root {
  --ntc-text-mono-font: 'Segoe UI Mono', 'Ubuntu Mono', 'Cascadia Mono', 'Roboto Mono', Menlo, Monaco, Consolas,
    monospace;
}

.taiga-table-with-groups {
  /*
    Дублирую название класса для увеличения specificity. Конкретно заголовок
    в тагйовской таблице сделан топорно и не даёт возможности его заменить целиком.
    Поэтому его селектор для стилей `.t-heading[_ngcontent-...] Specificity: (0,2,0)`
    необходимо перебивать таким образом.
  */
  .t-heading.t-heading {
    transition: none;
    font: var(--tui-font-text-m);
    font-weight: 500;
    padding: 0 0 0.5rem;
    height: auto;
    background-color: transparent;
    border-bottom: none;

    &:hover {
      background-color: transparent;
    }

    .t-expand {
      font-weight: inherit;
      padding: 0.25rem 0.75rem;
      border-radius: 0.5rem;
      background-color: var(--tui-background-neutral-1-hover);
      transition: background-color 0.3s ease-in-out;

      &:before,
      &:after {
        border: none;
      }

      &:hover,
      &:active {
        background-color: var(--tui-service-selection-background);
      }
    }
  }
}

/* После перерисовки tui-expand почему-то схлопывается параметр left конкретно в нашем приложении. На демке таких приколов нет */
.tui-table {
  &--collapsable {
    /*
      Дублирую название класса для увеличения specificity. Конкретно заголовок
      в тагйовской таблице сделан топорно и не даёт возможности его заменить целиком.
      Поэтому его селектор для стилей `.t-heading[_ngcontent-...] Specificity: (0,2,0)`
      необходимо перебивать таким образом.
    */
    .t-heading.t-heading .t-name {
      padding-left: 1rem;
    }
  }
}
