.block-status {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 40rem;
  text-align: center;
  margin: 0 auto;
  padding: 2rem 0;

  &__title {
    font: var(--tui-font-text-xl);
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  &__subtitle {
    font: var(--tui-font-text-m);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &__icon {
    margin-bottom: 1rem;
    color: var(--tui-text-secondary);
    background-color: var(--tui-background-base-alt);

    /*
      Теперь бордюр вместо паддинга, поскольку изменилась верстка tui-svg на tui-icon и нет возможности,
      не добавляя дополнительный контейнер во все места, иначе это исправить.
    */
    border: 1rem solid transparent;
    border-radius: 1rem;
    box-sizing: content-box;
  }

  &__message {
    color: var(--tui-text-secondary);
    margin-bottom: 0.5rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
  }

  &--error &__icon {
    color: var(--tui-status-negative);
    background-color: var(--tui-status-negative-pale);
  }

  &--info &__icon {
    color: var(--tui-status-info);
    background-color: var(--tui-status-info-pale);
  }

  &--primary &__icon {
    color: var(--tui-background-accent-1);
    background-color: var(--tui-status-info-pale);
  }
}
